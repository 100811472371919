// Generated by purs version 0.13.6
"use strict";
var Global_Unsafe = require("../Global.Unsafe/index.js");
var Routing_Match = require("../Routing.Match/index.js");
var Routing_Parser = require("../Routing.Parser/index.js");
var matchWith = function (decoder) {
    return function (matcher) {
        var $0 = Routing_Match.runMatch(matcher);
        var $1 = Routing_Parser.parse(decoder);
        return function ($2) {
            return $0($1($2));
        };
    };
};
var match = matchWith(Global_Unsafe.unsafeDecodeURIComponent);
module.exports = {
    match: match,
    matchWith: matchWith
};
