// Generated by purs version 0.13.6
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var V = function (x) {
    return x;
};
var unV = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Data_Either.Left) {
                return v(v2.value0);
            };
            if (v2 instanceof Data_Either.Right) {
                return v1(v2.value0);
            };
            throw new Error("Failed pattern match at Data.Validation.Semiring (line 48, column 1 - line 48, column 77): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var toEither = function (v) {
    return v;
};
var showV = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            if (v instanceof Data_Either.Left) {
                return "invalid (" + (Data_Show.show(dictShow)(v.value0) + ")");
            };
            if (v instanceof Data_Either.Right) {
                return "pure (" + (Data_Show.show(dictShow1)(v.value0) + ")");
            };
            throw new Error("Failed pattern match at Data.Validation.Semiring (line 81, column 10 - line 83, column 55): " + [ v.constructor.name ]);
        });
    };
};
var newtypeV = new Data_Newtype.Newtype(function (n) {
    return n;
}, V);
var isValid = function (v) {
    if (v instanceof Data_Either.Right) {
        return true;
    };
    return false;
};
var invalid = function ($71) {
    return V(Data_Either.Left.create($71));
};
var functorV = Data_Either.functorEither;
var foldableV = new Data_Foldable.Foldable(function (dictMonoid) {
    return unV(Data_Function["const"](Data_Monoid.mempty(dictMonoid)));
}, function (f) {
    return function (b) {
        return unV(Data_Function["const"](b))(f(b));
    };
}, function (f) {
    return function (b) {
        return unV(Data_Function["const"](b))(Data_Function.flip(f)(b));
    };
});
var traversableV = new Data_Traversable.Traversable(function () {
    return foldableV;
}, function () {
    return functorV;
}, function (dictApplicative) {
    return unV((function () {
        var $72 = Control_Applicative.pure(dictApplicative);
        return function ($73) {
            return $72(V(Data_Either.Left.create($73)));
        };
    })())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($74) {
        return V(Data_Either.Right.create($74));
    }));
}, function (dictApplicative) {
    return function (f) {
        return unV((function () {
            var $75 = Control_Applicative.pure(dictApplicative);
            return function ($76) {
                return $75(V(Data_Either.Left.create($76)));
            };
        })())((function () {
            var $77 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($79) {
                return V(Data_Either.Right.create($79));
            });
            return function ($78) {
                return $77(f($78));
            };
        })());
    };
});
var eqV = function (dictEq) {
    return function (dictEq1) {
        return new Data_Eq.Eq(function (x) {
            return function (y) {
                return Data_Eq.eq(Data_Either.eqEither(dictEq)(dictEq1))(x)(y);
            };
        });
    };
};
var ordV = function (dictOrd) {
    return function (dictOrd1) {
        return new Data_Ord.Ord(function () {
            return eqV(dictOrd.Eq0())(dictOrd1.Eq0());
        }, function (x) {
            return function (y) {
                return Data_Ord.compare(Data_Either.ordEither(dictOrd)(dictOrd1))(x)(y);
            };
        });
    };
};
var eq1V = function (dictEq) {
    return new Data_Eq.Eq1(function (dictEq1) {
        return Data_Eq.eq(eqV(dictEq)(dictEq1));
    });
};
var ord1V = function (dictOrd) {
    return new Data_Ord.Ord1(function () {
        return eq1V(dictOrd.Eq0());
    }, function (dictOrd1) {
        return Data_Ord.compare(ordV(dictOrd)(dictOrd1));
    });
};
var bifunctorV = Data_Either.bifunctorEither;
var applyV = function (dictSemiring) {
    return new Control_Apply.Apply(function () {
        return functorV;
    }, function (v) {
        return function (v1) {
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(Data_Semiring.mul(dictSemiring)(v.value0)(v1.value0));
            };
            if (v instanceof Data_Either.Left) {
                return new Data_Either.Left(v.value0);
            };
            if (v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(v1.value0);
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                return new Data_Either.Right(v.value0(v1.value0));
            };
            throw new Error("Failed pattern match at Data.Validation.Semiring (line 89, column 1 - line 93, column 54): " + [ v.constructor.name, v1.constructor.name ]);
        };
    });
};
var semigroupV = function (dictSemiring) {
    return function (dictSemigroup) {
        return new Data_Semigroup.Semigroup(Control_Apply.lift2(applyV(dictSemiring))(Data_Semigroup.append(dictSemigroup)));
    };
};
var applicativeV = function (dictSemiring) {
    return new Control_Applicative.Applicative(function () {
        return applyV(dictSemiring);
    }, function ($80) {
        return V(Data_Either.Right.create($80));
    });
};
var monoidV = function (dictSemiring) {
    return function (dictMonoid) {
        return new Data_Monoid.Monoid(function () {
            return semigroupV(dictSemiring)(dictMonoid.Semigroup0());
        }, Control_Applicative.pure(applicativeV(dictSemiring))(Data_Monoid.mempty(dictMonoid)));
    };
};
var andThen = function (v1) {
    return function (f) {
        return unV(invalid)(f)(v1);
    };
};
var altV = function (dictSemiring) {
    return new Control_Alt.Alt(function () {
        return functorV;
    }, function (v) {
        return function (v1) {
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(Data_Semiring.add(dictSemiring)(v.value0)(v1.value0));
            };
            if (v instanceof Data_Either.Left) {
                return v1;
            };
            if (v instanceof Data_Either.Right) {
                return new Data_Either.Right(v.value0);
            };
            throw new Error("Failed pattern match at Data.Validation.Semiring (line 104, column 1 - line 107, column 36): " + [ v.constructor.name, v1.constructor.name ]);
        };
    });
};
var plusV = function (dictSemiring) {
    return new Control_Plus.Plus(function () {
        return altV(dictSemiring);
    }, new Data_Either.Left(Data_Semiring.zero(dictSemiring)));
};
module.exports = {
    V: V,
    unV: unV,
    invalid: invalid,
    isValid: isValid,
    toEither: toEither,
    andThen: andThen,
    newtypeV: newtypeV,
    eqV: eqV,
    eq1V: eq1V,
    ordV: ordV,
    ord1V: ord1V,
    showV: showV,
    functorV: functorV,
    bifunctorV: bifunctorV,
    applyV: applyV,
    applicativeV: applicativeV,
    semigroupV: semigroupV,
    monoidV: monoidV,
    altV: altV,
    plusV: plusV,
    foldableV: foldableV,
    traversableV: traversableV
};
