// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Effect = require("../Effect/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var RequestIdleCallbackId = function (x) {
    return x;
};
var RequestAnimationFrameId = function (x) {
    return x;
};
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var requestIdleCallback = function (opts) {
    return function (fn) {
        var $30 = Data_Functor.map(Effect.functorEffect)(RequestIdleCallbackId);
        var $31 = $foreign["_requestIdleCallback"](opts)(fn);
        return function ($32) {
            return $30($31($32));
        };
    };
};
var requestAnimationFrame = function (fn) {
    var $33 = Data_Functor.map(Effect.functorEffect)(RequestAnimationFrameId);
    var $34 = $foreign["_requestAnimationFrame"](fn);
    return function ($35) {
        return $33($34($35));
    };
};
var promptDefault = function (msg) {
    return function (defaultText) {
        return function (window) {
            return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_prompt"](msg)(defaultText)(window));
        };
    };
};
var prompt = function (msg) {
    return function (window) {
        return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_prompt"](msg)("")(window));
    };
};
var opener = function (window) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_opener"](window));
};
var open = function (url$prime) {
    return function (name) {
        return function (features) {
            return function (window) {
                return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_open"](url$prime)(name)(features)(window));
            };
        };
    };
};
var newtypeRequestIdleCallbackId = new Data_Newtype.Newtype(function (n) {
    return n;
}, RequestIdleCallbackId);
var newtypeRequestAnimationFrameId = new Data_Newtype.Newtype(function (n) {
    return n;
}, RequestAnimationFrameId);
var eqRequestIdleCallbackId = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordRequestIdleCallbackId = new Data_Ord.Ord(function () {
    return eqRequestIdleCallbackId;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
    };
});
var eqRequestAnimationFrameId = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordRequestAnimationFrameId = new Data_Ord.Ord(function () {
    return eqRequestAnimationFrameId;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
    };
});
var cancelIdleCallback = function (idAF) {
    return $foreign["_cancelIdleCallback"](Data_Newtype.unwrap(newtypeRequestIdleCallbackId)(idAF));
};
var cancelAnimationFrame = function (idAF) {
    return $foreign["_cancelAnimationFrame"](Data_Newtype.unwrap(newtypeRequestAnimationFrameId)(idAF));
};
module.exports = {
    toEventTarget: toEventTarget,
    open: open,
    prompt: prompt,
    promptDefault: promptDefault,
    requestAnimationFrame: requestAnimationFrame,
    cancelAnimationFrame: cancelAnimationFrame,
    requestIdleCallback: requestIdleCallback,
    cancelIdleCallback: cancelIdleCallback,
    opener: opener,
    newtypeRequestAnimationFrameId: newtypeRequestAnimationFrameId,
    eqRequestAnimationFrameId: eqRequestAnimationFrameId,
    ordRequestAnimationFrameId: ordRequestAnimationFrameId,
    newtypeRequestIdleCallbackId: newtypeRequestIdleCallbackId,
    eqRequestIdleCallbackId: eqRequestIdleCallbackId,
    ordRequestIdleCallbackId: ordRequestIdleCallbackId,
    document: $foreign.document,
    navigator: $foreign.navigator,
    location: $foreign.location,
    history: $foreign.history,
    innerWidth: $foreign.innerWidth,
    innerHeight: $foreign.innerHeight,
    alert: $foreign.alert,
    confirm: $foreign.confirm,
    moveBy: $foreign.moveBy,
    moveTo: $foreign.moveTo,
    outerHeight: $foreign.outerHeight,
    outerWidth: $foreign.outerWidth,
    print: $foreign.print,
    resizeBy: $foreign.resizeBy,
    resizeTo: $foreign.resizeTo,
    screenX: $foreign.screenX,
    screenY: $foreign.screenY,
    scroll: $foreign.scroll,
    scrollBy: $foreign.scrollBy,
    scrollX: $foreign.scrollX,
    scrollY: $foreign.scrollY,
    localStorage: $foreign.localStorage,
    sessionStorage: $foreign.sessionStorage,
    parent: $foreign.parent
};
